<template>
  <div class="mx-auto grid max-w-sm grid-cols-1 gap-4 p-2 sm:max-w-6xl">
    <router-link v-for="event in events" :key="event.id" :to="event.can_enter || event.can_buy_spectator_ticket ? `/event/${event.url}` : ''" class="flex flex-col overflow-hidden rounded-md border sm:flex-row" :class="{ 'pointer-events-none': !(event.can_enter || event.can_buy_spectator_ticket) }">
      <div class="sm:w-1/4">
        <cgn-lazy-image :image="event.type.photo" class="w-full" />
      </div>
      <div class="flex flex-col p-2 sm:w-3/4">
        <div class="flex justify-between">
          <div class="text-2xl font-bold sm:text-4xl">
            {{ event.name }}
          </div>
          <div>
            <cgn-button color-brand>
              Buy Tickets
            </cgn-button>
          </div>
        </div>
        <div class="pb-2 text-xl font-semibold sm:text-2xl">
          {{ event.venue.name }}
        </div>
        <div class="text-lg font-semibold sm:text-xl">
          <div>
            {{ event.start_date.toHumanDateString(true) }}
          </div>
          <div v-if="event.dates">
            <div>
              {{ event.dates[0]?.start.toHumanTimeString() }}
            </div>
          </div>
        </div>
      </div>
    </router-link>
  </div>
</template>

<script lang="ts">
import { EventEvent } from '~cognito/models/Event/Event'

class Templatevars {
}
</script>

<script setup lang="ts">
const props = defineProps({
  templatevar: {
    type: Templatevars,
    required: true,
  },
})
const events = ref<EventEvent[]>([])

async function loadEvents() {
  const data = await new EventEvent().find_many({
    onlyupcoming: true,
    image_aspect: '1x1',
  })
  events.value = data.mapped
}
onMounted(() => {
  loadEvents()
})
onServerPrefetch(async () => {
  await loadEvents()
})
</script>
