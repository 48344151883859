<template>
  <div class="bg-secondary-900 font-bold text-white text-center text-lg pt-8 pb-4">
    <div class="text-3xl uppercase pb-4">
      Upcoming Roll Racing Australia Events
    </div>
    <div class="grid grid-cols-1 sm:grid-cols-3">
      <router-link v-for="(event, index) in events" :key="event.id" :to="`/event/${event.url}`" class="pb-8">
        <cgn-lazy-image :image="event.type.photo" class="w-full sm:w-2/3 mx-auto aspect-square" />
        <div class="text-3xl uppercase">
          Roll Racing
        </div>
        <div class="text-3xl uppercase" :class="['text-blue-500', 'text-brand-500', 'text-yellow-500'][index]" :style="`color: ${event.venue.colour}`">
          {{ ['Sydney', 'Adelaide', 'Perth'][index] }}
        </div>
        <div>{{ event.start_date.format('MMMM do yyyy') }}</div>
        <div>{{ event.venue.name }}</div>
      </router-link>
    </div>
  </div>
</template>

<script lang="ts">
import type { EventEvent } from '~cognito/models/Event/Event'
import { EventVenue } from '~cognito/models/Event/Venue'

class Templatevars {
}
</script>

<script setup lang="ts">
const props = defineProps({
  templatevar: {
    type: Templatevars,
    required: true,
  },
})

const events = ref<EventEvent[]>([])
async function loadEvents() {
  const data = await new EventVenue().nextEvents()
  events.value = data
}

onMounted(() => {
  loadEvents()
})

onServerPrefetch(async () => {
  await loadEvents()
})
</script>
