<template>
  <div v-if="event.url" class="bg-brand-500">
    <div class="py-3 text-center text-2xl font-bold text-white">
      {{ templatevar.heading }}
    </div>
    <div class="relative mb-8">
      <cgn-lazy-image :image="event.featured_desktop_image" class="hidden w-full sm:block" />
      <cgn-lazy-image :image="event.featured_mobile_image" class="w-full sm:hidden" />
      <div class="absolute inset-x-0 bottom-0 m-4 flex justify-between">
        <div>
          <cgn-button color-brand class="text-2xl font-bold uppercase" :url="`/event/${event.url}`">
            <i-fa6-solid:arrow-up-right-from-square class="mr-1 inline sm:mr-2 sm:h-7 sm:w-7" />
            Get tickets
          </cgn-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { gql } from 'graphql-request'
import { EventEvent } from '~cognito/models/Event/Event'
import { $axios } from '~cognito/plugins/axios'

class Templatevars {
  heading?: string
}
</script>

<script setup lang="ts">
const props = defineProps({
  templatevar: {
    type: Templatevars,
    required: true,
  },
})

const event = ref<EventEvent>(new EventEvent())

async function loadEvent() {
  const data = await $axios.graphql(gql`
  {
  eventEvents(first: 1, isFeatured: true) {
    featured_event
    featured_desktop_image(image_width: 1920, image_aspect: "16x5") {
      url
      width
      height
    }
    featured_mobile_image(image_width: 1920, image_aspect: "4x3") {
      width
      url
      height
    }
    url
  }
}
`)
  const events = new EventEvent().map(data.eventEvents)
  if (events.length) {
    event.value = events.pop()
  }
}
onMounted(() => {
  loadEvent()
})
onServerPrefetch(async () => {
  await loadEvent()
})
</script>
