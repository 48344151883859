import { config } from '../cognitocmsapi/default_config.js'

config.devURL = `https://rollracing.${config.devbase}`
config.prodURL = 'https://cms.rollracing.com.au'
config.siteURL = 'https://rollracing.com.au'
config.baseURL = config.devbase == '' ? config.prodURL : config.devURL

config.group_args.image_aspect = '4x3'
config.isEcommerce = true
config.profile.edit_date_of_birth = true
config.profile.edit_drivers_licence = true
config.profile.edit_emergency_contact = true
config.sentryDsn = 'https://6d3834cb7389491ca9dd0d1855f7f68d@glitchtip.logger.jm1.au/14'

export { config }
