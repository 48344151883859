<script lang="ts">
const image_width = 1184
</script>
<template>
  <div
    v-if="post.name"
    class="overflow-hidden"
  >
    <div
      v-if="post.image"
      class="absolute inset-y-0 left-3/4 hidden w-screen bg-gray-300 opacity-10 dark:bg-gray-600 lg:block"
    />
    <div class="text-base">
      <h1
        class="mt-2 text-center text-3xl font-extrabold leading-8 tracking-tight text-gray-900 dark:text-gray-100 sm:text-4xl"
        :class="{ 'lg:text-left': post.image }"
      >
        {{ post.name }}
      </h1>
    </div>
    <div class="mt-8 lg:grid lg:grid-cols-3 lg:gap-8">
      <div v-if="post.image" class="relative lg:col-start-3 lg:row-start-1">
        <svg
          class="absolute right-0 top-0 -mr-20 -mt-20 hidden lg:block"
          width="304"
          height="274"
          fill="none"
          viewBox="0 0 304 274"
          aria-hidden="true"
        >
          <defs>
            <pattern
              id="de316486-4a29-4312-bdfc-fbce2132a2c1"
              x="0"
              y="0"
              width="20"
              height="20"
              patternUnits="userSpaceOnUse"
            >
              <rect
                x="0"
                y="0"
                width="5"
                height="5"
                class="text-gray-200 dark:text-gray-800"
                fill="currentColor"
              />
            </pattern>
          </defs>
          <rect width="404" height="384" fill="url(#de316486-4a29-4312-bdfc-fbce2132a2c1)" />
        </svg>
        <div class="relative mx-auto max-w-prose text-base lg:max-w-none">
          <figure>
            <div class="aspect-w-4 aspect-h-3 lg:aspect-none">
              <cgn-lazy-image
                class="rounded-lg border bg-white object-cover object-center shadow"
                :image="post.image"
                width="1184"
              />
            </div>
          </figure>
        </div>
      </div>
      <div class="col-span-2 mt-8 lg:mt-0" :class="{ 'col-span-3': !post.image }">
        <div
          class="prose-brand prose mx-auto mt-5 max-w-2xl text-gray-600 lg:col-start-1 lg:row-start-1"
          v-html="post.content"
        />
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { CognitoArticle } from '~cognito/models/Cognito/Article'

const props = defineProps({
  article: null
})

const post = ref<CognitoArticle>(new CognitoArticle())

useSchemaOrg([
  defineArticle({
    // name and description can usually be inferred
    image: computed(() => post.value?.image?.url),
    // datePublished: new Date(2020, 1, 1),
    // dateModified: new Date(2020, 1, 1),
  }),
])



const breadcrumbs = ref([{ name: 'Blog', url: '/blog' }])

async function loadArticle(url: string) {
  post.value.name = ''
  breadcrumbs.value = [{ name: 'Blog', url: '/blog' }]
  const data = await new CognitoArticle().find_one({
    url,
    image_aspect: '4x3w',
    image_width,
  })
  post.value = data
  breadcrumbs.value.push({ name: post.value.groups[0].name, url: `/blog/${post.value.groups[0].slug}` })
  breadcrumbs.value.push({ name: post.value.name, url })
}

watch(() => props.article, (newUrl) => {
  loadArticle(newUrl)
})
onMounted(() => {
  loadArticle(props.article)
})
onServerPrefetch(async () => {
  await loadArticle(props.article)
})
</script>
