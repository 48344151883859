<template>
  <div>
    <div class="flex flex-col gap-2">
      <div
        v-for="post, index in posts"
        :key="index"
        class="flex flex-col justify-between overflow-hidden rounded-md border sm:flex-row"
      >
        <div v-if="post.image" class="sm:order-last sm:w-1/3">
          <router-link :to="`/article/${post.slug}`">
            <cgn-lazy-image
              :image="post.image"
            />
          </router-link>
        </div>
        <div class="flex w-full flex-col justify-between gap-2 bg-white p-4 dark:bg-darkbg-500" :class="{ 'sm:w-2/3': post.image }">
          <div class="grow">
            <router-link :to="`/article/${post.slug}`" class="block">
              <p class="text-2xl font-semibold text-gray-900 dark:text-gray-100">
                {{ post.name }}
              </p>
              <div class="mt-3 text-base text-gray-500 dark:text-gray-200" v-html="post.blurb" />
            </router-link>
          </div>
          <cgn-button color-brand fullwidth :url="`/article/${post.slug}`">
            Read
          </cgn-button>
        </div>
      </div>
    </div>
    <div class="flex grow justify-center py-5">
      <cgn-pagination
        :current-page="props.page"
        :page-count="num_pages"
        :group="props.group"
        url-prefix="blog"
      />
    </div>
  </div>
</template>

<script setup lang="ts">
import { CognitoGroup } from '~cognito/models/Cognito/Group'
import { CognitoArticle } from '~cognito/models/Cognito/Article'

const props = defineProps({
  group: {
    type: String,
    default: 'page',
  },
  page: {
    type: Number,
    default: 1,
  },
})

const posts = ref<CognitoArticle[]>([])
const groupDetails = ref<CognitoGroup>(new CognitoGroup())
const num_pages = ref(0)

async function loadArticles() {
  posts.value = []
  num_pages.value = 1
  let group = props.group
  const page = props.page
  if (group === 'page') {
    group = ''
  }
  const data = await new CognitoArticle().find_many({
    image_aspect: '6x3w',
    image_width: 600,
    page_size: 20,
    orderby: 'display_start_time',
    page,
    group,
  })
  posts.value = data.data
  num_pages.value = data.num_pages

  groupDetails.value = new CognitoGroup()
  if (group) {
    groupDetails.value = await new CognitoGroup().findByUrl('Cognito\\Article', group)
  }
}
watch(() => props, () => {
  loadArticles()
}, {
  deep: true,
})
onMounted(() => {
  loadArticles()
})
onServerPrefetch(async () => {
  await loadArticles()
})
</script>
