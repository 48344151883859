<template>
  <div class="max-w-sm sm:max-w-6xl mx-auto gap-4 grid grid-cols-1 p-2">
    <div v-for="merch in merchs" :key="merch.id" class="border rounded-md overflow-hidden flex flex-col sm:flex-row">
      <div v-for="photo in merch.photos" :key="photo.id">
        <cgn-lazy-image :image="photo" width="300" class="w-full sm:w-64" />
      </div>
      <div class="p-1 flex flex-col w-full">
        <div class="flex justify-between">
          <div class="text-2xl">
            {{ merch.name }}
          </div>
          <div class="text-3xl">
            ${{ merch.price_each }}
          </div>
        </div>
        <div>
          {{ merch.description }}
        </div>
        <div v-if="merch.options.length > 0" class="p-2">
          Available in:
          <div class="border-l-2 p-1">
            <div v-for="option in merch.options" :key="option.id" class="font-bold">
              {{ option.name }}
              <span v-if="option.price_each > 0">
                ${{ option.price_each }}
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { EventMerch } from '~cognito/models/Event/Merch'

class Templatevars {
}
</script>

<script setup lang="ts">
const props = defineProps({
  templatevar: {
    type: Templatevars,
    required: true,
  },
})
const merchs = ref<EventMerch[]>([])

async function loadMerch() {
  const data = await new EventMerch().find_many({
    page_size: 50,
  })
  merchs.value = data.data
}
onMounted(() => {
  loadMerch()
})
onServerPrefetch(async () => {
  await loadMerch()
})
</script>
