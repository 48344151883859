<template>
  <cgn-tabs v-slot="tabData" :tabs="generateTabs(venues)">
    <div v-for="(venue, index) in venues" v-show="tabData.selected_tab == venue.name" :key="index">
      <cgn-lazy-image :image="venue.photo" class="w-1/2 mx-auto" />
      <div class="prose max-w-none" v-html="venue.content" />
      <div class="grid grid-cols-2 gap-2">
        <cgn-lazy-image v-for="photo in venue.photos" :key="photo.id" :image="photo" class="w-full" />
      </div>
    </div>
  </cgn-tabs>
</template>

<script lang="ts">
import { EventVenue } from '~cognito/models/Event/Venue'

class Templatevars {
}
</script>

<script setup lang="ts">
const props = defineProps({
  templatevar: {
    type: Templatevars,
    required: true,
  },
})

function generateTabs(venues: EventVenue[]) {
  const tab_list = ref([])
  venues.forEach((venue: EventVenue, index: Number) => {
    tab_list.value.push({ name: venue.name, id: index })
  })
  return tab_list.value
}

const venues = ref<EventVenue[]>([])

async function loadVenues() {
  const data = await new EventVenue().find_many({
    page_size: 50,
  })
  venues.value = data.data
}
onMounted(() => {
  loadVenues()
})
onServerPrefetch(async () => {
  await loadVenues()
})
</script>
